<template>
  <v-form class="pa-5" ref="form" v-model="valid">
    <v-row>
      <v-col cols="12" sm="2">
        <div style="height: 30px"></div>
        <v-text-field
          v-model="config.frontLeft"
          :rules="$rules.requiredNumber"
          label="Front Left"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        ></v-text-field>
        <div v-if="$vuetify.breakpoint.name !== 'xs'" style="height: 100px"></div>
        <v-text-field
          v-model="config.rearLeft"
          :rules="$rules.requiredNumber"
          label="Rear Left"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-row justify="center"
          ><v-col cols="auto"> <v-img src="@assets/car-top-alpha.png" width="200" height="350"></v-img> </v-col
        ></v-row>
      </v-col>
      <v-col cols="12" sm="2">
        <div style="height: 30px"></div>
        <v-text-field
          v-model="config.frontRight"
          :rules="$rules.requiredNumber"
          label="Front Right"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        ></v-text-field>
        <div v-if="$vuetify.breakpoint.name !== 'xs'" style="height: 100px"></div>
        <v-text-field
          v-model="config.rearRight"
          :rules="$rules.requiredNumber"
          label="Rear Right"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'SetupWheelForm',
  model: {
    prop: 'config',
    event: 'input'
  },
  props: {
    unit: String,
    config: Object
  },
  data: () => ({
    valid: false
  }),
  methods: {
    validate() {
      const valid = this.$refs.form.validate()
      this.$emit('validate', valid)
      return valid
    }
  },
  watch: {
    valid() {
      this.$emit('validate', this.valid)
    }
  }
}
</script>
